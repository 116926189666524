<template>
  <b-form ref="register-form">
    <template v-if="currentStep === 1">
      <b-button
        variant="link"
        :to="{ path: '/' }"
      >
        <i class="fa fa-home" />
      </b-button>
      <div class="p-3">
        <h3 class="text-center text-uppercase my-3">
          Select User Type
        </h3>
        <b-row class="mx-auto">
          <b-col
            v-for="(user_type, u) in user_types"
            :key="u"
            class="text-center cursor-pointer"
            @click="handleSelectUser(user_type)"
          >
            <b-img-lazy
              fluid
              :src="user_type.icon"
              :alt="user_type.slug"
              width="120px"
            />
            <h5 class="mt-2 text-capitalize font-weight-bolder">
              {{ user_type.name }}
            </h5>
          </b-col>
        </b-row>
      </div>
    </template>

    <template v-else-if="currentStep === 2">
      <b-button
        variant="link"
        @click="handleDeSelectUser()"
      >
        <i class="fa fa-arrow-left" />
      </b-button>

      <b-row class="p-3">
        <b-col
          class="animated d-flex justify-content-center align-items-center"
          data-animation="fadeInLeftShorter"
          data-animation-delay="0.5s"
        >
          <router-link :to="{ path: '/' }">
            <b-img-lazy
              fluid
              :src="hmip"
              alt="Logo"
            />
          </router-link>
        </b-col>
        <b-col
          class="animated"
          data-animation="fadeInRightShorter"
          data-animation-delay="0.5s"
        >
          <div class="text-center mb-4">
            <h3 class="m-0">
              Welcome to HMIP
            </h3>
            <small>Create an account to continue</small>
          </div>

          <b-row>
            <b-col>
              <b-form-group
                id="input-register-first_name"
                label="First Name:"
                invalid-feedback="First Name is required"
                class="mb-3"
              >
                <b-form-input
                  id="register-first_name"
                  v-model="$v.register.Firstname.$model"
                  :state="validateState($v.register.Firstname)"
                  type="text"
                  placeholder="First Name"
                  required
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                id="input-register-last_name"
                invalid-feedback="Last Name is required"
                label="Last Name:"
                class="mb-3"
              >
                <b-form-input
                  id="register-last_name"
                  v-model="$v.register.Lastname.$model"
                  :state="validateState($v.register.Lastname)"
                  type="text"
                  placeholder="Last Name"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group
            id="input-register-email"
            invalid-feedback="Email Address is required and must be a valid email"
            label="Email Address:"
            class="mb-3"
          >
            <b-form-input
              id="register-email"
              v-model="$v.register.Email.$model"
              :state="validateState($v.register.Email)"
              type="email"
              placeholder="Email Address"
              autocomplete="new-email"
              required
            />
          </b-form-group>

          <b-form-group
            id="input-register-phone"
            invalid-feedback="Phone Number is required and must be a valid number"
            label="Phone Number:"
            class="mb-3"
          >
            <b-form-input
              id="register-phone"
              v-model="$v.register.PhoneNumber.$model"
              :state="validateState($v.register.PhoneNumber)"
              type="tel"
              placeholder="Phone Number"
              required
            />
          </b-form-group>

          <b-form-group
            v-for="(role, r) in register.RoleIDs"
            id="input-register-role_id"
            :key="r"
            label="Role:"
            class="mb-3 d-none"
          >
            <b-form-input
              id="register-role_id"
              :value="role"
              type="text"
              required
              readonly
            />
          </b-form-group>

          <b-form-group
            id="input-register-password"
            label="Password:"
            invalid-feedback="Password is required and must be at least 8 characters"
            class="mb-3 position-relative"
          >
            <b-form-input
              id="register-password"
              v-model="$v.register.Password.$model"
              :state="validateState($v.register.Password)"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Password"
              autocomplete="new-password"
              required
            />
            <div
              v-show="validateState($v.register.Password)"
              class="input-prepend"
              @click="showPassword = !showPassword"
            >
              <BIconEyeSlash v-if="showPassword" />
              <BIconEye v-else />
            </div>
          </b-form-group>

          <!-- <vue-recaptcha
            ref="recaptcha"
            sitekey="6LdVWZ4qAAAAAGy0aUacNqB03bCb3MmKKsouhUGR"
          /> -->

          <div class="mb-3 d-flex align-items-center">
            <b-form-checkbox
              id="terms"
              v-model="$v.terms.$model"
              name="terms"
              :state="validateState($v.terms)"
              value="1"
              class="p-0"
            />
            <span class="pl-4">
              I accept the <a
                href="https://nmrc.com.ng/privacy-policy-of-the-nigeria-mortgage-refinance-company-plc/"
                target="_blank"
                rel="noopener noreferrer"
              >Terms & Conditions</a>
            </span>
          </div>

          <div class="mb-3 d-flex align-items-center">
            <b-form-checkbox
              id="captcha"
              name="captcha" 
              class="p-0"
              value="1" 
              @change="handleRecaptcha($event)"
            />
            <span class="pl-4">
              I am human 
            </span>
            <span>
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/RecaptchaLogo.svg/130px-RecaptchaLogo.svg.png">
            </span>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-4">
            <button
              type="submit"
              style="background-color:green; color: white;"
              class="btn btn-gradient-purple btn-glow"
              :disabled="! captchaPassed"
              @click.prevent="handleRegister"
            >
              Register
              <b-spinner
                v-if="submitting"
                class="ml-2"
                label="Loading..."
                small
              />
            </button>
            <div>
              <small>Already Have an Account?</small>
              <a
                href="#"
                class="text-sm pl-2"
                :disabled="submitting"
                @click="handleChangeAuthContainer('login')"
              >
                <small>Login</small>
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-form>
</template>
<script>
import { validationMixin } from "vuelidate"
import { required, email, minLength } from "vuelidate/lib/validators"
import user_icon from '../../assets/icons/user.svg'
import house_icon from '../../assets/icons/house.svg'
import logo from '../../assets/theme-assets/images/loader-logo.png'
import axios from 'axios';
import { BIconEye, BIconEyeSlash } from 'bootstrap-vue'



export default {
  name: 'AuthRegisterForm',
  components: { BIconEye, BIconEyeSlash },
  mixins: [validationMixin],
  emits: ['change_auth_view'],


  data: function () {
    return {
      currentStep: 1,
      hmip: logo,
      captchaPassed: false,
      submitting: false,
      terms_warning: false,
      showPassword: false,
      terms: '',

      register: {
        Email: '',
        Firstname: '',
        Lastname: '',
        PhoneNumber: '',
        Password: '',
        RoleIDs: [],
      },
      user_types: [
        {
          icon: house_icon,
          name: 'Real Estate Developer',
          slug: 'real_estate_developer'
        },
        {
          icon: user_icon,
          name: 'Regular User',
          slug: 'regular_user'
        },
      ],
    }
  },

  validations: {
    register: {
      Firstname: {
        required
      },
      Lastname: {
        required
      },
      PhoneNumber: {
        required,
      },
      Email: {
        required,
        email
      },
      Password: {
        required,
        minLength: minLength(8)
      },
    },
    terms: {
      required,
    },
    captach: {
      required,
    }
  },

  mounted() {
    this.currentStep = 1;
  },
  methods: {
    async handleRecaptcha() {
   
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();
      
      // Execute reCAPTCHA with action "search".
      const token = await this.$recaptcha('search');

      if(token != undefined){
        this.captchaPassed = true;
      }
      
      // Do stuff with the received token.
      console.log(token); // OK, getting unique tokens every time. Now what?!?
    },
    
  
  handleChangeAuthContainer(view) {
    this.$emit('change_auth_view', view);
  },

  validateState(register) {
    const { $dirty, $error } = register
    return $dirty ? !$error : null
  },

  async handleRegister() {
    this.$v.register.$touch()
    this.$v.terms.$touch()
    if (this.$v.register.$anyError) return false;
    if (this.$v.terms.$anyError) return false;

 
        this.submitting = true;

    try {

      const { data, status } = await axios.post('User/create', this.register)

      if (status === 200) {
        // Save the token.
        this.$store.dispatch('auth/saveToken', {
          token: data.details.token.token,
          remember: this.remember,
        });

        // update user data 
        this.$store.dispatch('auth/updateUser', {
          user: data.details
        })

        // redirect
        this.$router.push(this.$route.query.redirect || '/');
        this.submitting = false;

      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops! something went wrong',
          text: data.message ?? 'An error occured'
        });
      }

    } catch (error) {
      this.submitting = false;
      this.$swal({
        icon: 'error',
        title: 'Oops! something went wrong',
        text: error.data.message ?? 'HINT: Refresh and try again'
      });
    }
    this.submitting = false;

      
    

  },

  handleSelectUser(user_type) {
    this.currentStep++;
    this.register.RoleIDs.push(user_type.name);
  },

  handleDeSelectUser() {
    this.currentStep--;
    this.register.RoleIDs = [];
  },
},
};
</script>
